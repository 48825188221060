import './App.css'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'

import Home from './Home'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/:id' element={<Home />} />
      </Routes>
    </Router>
  )
}

export default App
